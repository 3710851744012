@import "../../_globalColor";

.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 25px;
}

.fixedButton {
  border: none;
  outline: none;
  background-color: $buttonColor;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
  font-size: 20px;
}

.fixedButton:hover {
  background-color: $topButtonHover;
  transition: all ease-in-out 0.2s;
}
