@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}
.dark-mode {
  color: $textColorDark !important;
}
