@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}
.skills-listing {
  display: flex;
  flex-wrap: wrap;
}
.new-separator {
  color: #132128;
  padding: 0;
  box-sizing: border-box;
  border: 0 solid;
  margin: 0 4px;
  background-color: #899094;
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
}
.new-years {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0 solid;
  color: #424d53;
}
.new {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  box-sizing: border-box;
  border: 0 solid;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter, serif;
  font-weight: 400;
  background-color: #e0f3f5;
  color: #132128;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin: 0 8px 8px 0;
}
.newWrapper > div.new:hover {
  background-color: $skillsColor;
  color: white;
  cursor: pointer;
}
.newWrapper {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  line-height: 24px;
  word-break: break-all;
  white-space: pre-wrap;
  font-family: Inter, serif;
  font-weight: 400;
  color: #424d53;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0 solid;
  display: flex;
  flex-wrap: wrap;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}
@media (min-width: 361px) and (max-width: 768px) {
  .newWrapper .new {
    font-size: 10px;
    text-align: center;
  }
}

@media (max-width: 361px) {
  .newWrapper .new {
    font-size: 10px;
  }
}
